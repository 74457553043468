@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: "Poppins";
  src: url("./utils/fonts/Poppins/Poppins-Regular.ttf");
  weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Semibold";
  src: url("./utils/fonts/Poppins/Poppins-SemiBold.ttf");
  weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./utils/fonts/Poppins/Poppins-Bold.ttf");
  weight: 700;
  font-style: normal;
  font-display: swap;
}

@layer utilities {
  .text-gradient {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.ukraine {
  background: linear-gradient(rgb(0, 91, 187) 50%, rgb(255, 213, 0) 50%);
}
